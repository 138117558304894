import React, { useState } from 'react';
import DayDropdown from './DayDropdown';
import MonthDropdown from './MonthDropdown';
import styled from '../../../styledComponents';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { nb } from 'date-fns/locale';
import { useSelectedFiltersContext } from '../../../hooks/useSelectedFiltersContext';

const DateFilter = () => {
  const { state, dispatch } = useSelectedFiltersContext();
  const dropdownDaySelect = (days: number) => {
    dispatch({ type: 'SET_DAYSFILTER', days });
  };
  const dropdownMonthSelect = (month: string) => {
    dispatch({ type: 'SET_MONTHFILTER', month });
  };
  const selectToDate = (date: Date | null) => {
    if (date) {
      // Adjusting for timezone offset
      const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      dispatch({
        type: 'SET_DATEFILTER',
        dateFilter: { fromDate: state.dateFilter && state.dateFilter.fromDate, toDate: isoDateTime },
      });
    } else {
      dispatch({
        type: 'SET_DATEFILTER',
        dateFilter: { fromDate: state.dateFilter && state.dateFilter.fromDate, toDate: date },
      });
    }
  };
  const selectFromDate = (date: Date | null) => {
    if (date) {
      // Adjusting for timezone offset
      const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      dispatch({
        type: 'SET_DATEFILTER',
        dateFilter: { fromDate: isoDateTime, toDate: state.dateFilter && state.dateFilter.toDate },
      });
    } else {
      dispatch({
        type: 'SET_DATEFILTER',
        dateFilter: { fromDate: date, toDate: state.dateFilter && state.dateFilter.toDate },
      });
    }
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const MenuCollapseClicked = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledDropdown isMenuOpen={true}>
        <DayDropdown selectedDayFilter={state.days} onSelect={dropdownDaySelect} />
      </StyledDropdown>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
          Flere valg
        </h4>
        <div style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
          <StyledArrow aria-label={'show or hide filters'} isMenuOpen={isMenuOpen} />
        </div>
      </div>
      <StyledDropdown isMenuOpen={isMenuOpen}>
        <MonthDropdown selectedMonth={state.month} onSelect={dropdownMonthSelect} />
      </StyledDropdown>
      <StyledDateComponent isMenuOpen={isMenuOpen}>
        <div>
          <StyledLabel htmlFor='From'>Fra:</StyledLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
            <DatePicker
              format='dd.MM.yyyy'
              maxDate={new Date()}
              slotProps={{ textField: { placeholder: 'Velg fra dato' } }}
              value={state.dateFilter?.fromDate ? new Date(state.dateFilter.fromDate) : null}
              onChange={(val: Date | null) => selectFromDate(val)}
            />
          </LocalizationProvider>
        </div>
      </StyledDateComponent>
      <StyledDateComponent isMenuOpen={isMenuOpen}>
        <div>
          <StyledLabel htmlFor='To'>Til:</StyledLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
            <DatePicker
              format='dd.MM.yyyy'
              slotProps={{ textField: { placeholder: 'Velg til dato' } }}
              value={state.dateFilter?.toDate ? new Date(state.dateFilter.toDate) : null}
              maxDate={new Date()}
              onChange={selectToDate}
            />
          </LocalizationProvider>
        </div>
      </StyledDateComponent>
    </div>
  );
};

export default DateFilter;

const StyledDropdown = styled.div<{ isMenuOpen: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  label {
    padding-right: 6px;
  }
`;
const StyledDateComponent = styled.div<{ isMenuOpen: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  label {
    padding-right: 6px;
  }
  div {
    margin-bottom: 1px !important;
  }
  .react-datepicker__input-container input {
    margin: 1px !important;
    height: 36px !important;
  }
`;
const StyledArrow = styled.i<{ isMenuOpen: boolean }>`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.5px;
  margin-left: 5px;
  transform: rotate(${(props) => (props.isMenuOpen ? '-135deg' : '45deg')});
`;
const StyledLabel = styled.label`
  margin-right: ${(props) => (props.htmlFor === 'To' ? '5px' : '0px')};
`;
