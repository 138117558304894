import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useIframeContext } from '../../hooks/useIframeContext';
import { useSelectedFiltersContext } from '../../hooks/useSelectedFiltersContext';
import { useMutationState } from '@tanstack/react-query';
import { getIframeMutationKey, IframeMutationResponse, useGetIframeMutation } from '../../hooks/useGetIframeMutation';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ToiBox } from '@norkart/toi-components';
import FilterComponent from '../../components/filtersFolder/FilterComponent';
import OmsetningIframe from '../../components/omsetningIframe/OmsetningIframe';
import OmsetningFileDownload from '../../components/omsetningDownload/omsetningFileDownload';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <ToiBox sx={{ p: 3 }}>{children}</ToiBox>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MainContent = () => {
  const { iframeKey, incrementIframeKey } = useIframeContext();
  const { state } = useSelectedFiltersContext();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const mutation = useGetIframeMutation({ incrementIframeKey }, state);

  const mutations = useMutationState({
    filters: { mutationKey: getIframeMutationKey },
    select: (mutation) => mutation.state,
  });

  const iframeData = useMemo(() => {
    if (mutations.length === 0) return;
    return mutations[mutations.length - 1].data as IframeMutationResponse | undefined;
  }, [mutations]);

  useEffect(() => {
    if (!mutation.data && !iframeData) {
      mutation.mutate(false);
    }
  }, [mutation.data, iframeData]);

  return (
    <div>
      <FilterComponent />
      <ToiBox sx={{ width: '100%' }}>
        <ToiBox sx={{ justifyContent: 'center', display: 'flex' }}>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            <Tab label='Kart' {...a11yProps(0)} />
            <Tab label='Tabell' {...a11yProps(1)} />
            <Tab label='Nedlastning' {...a11yProps(2)} />
          </Tabs>
        </ToiBox>
        <CustomTabPanel value={value} index={0}>
          <OmsetningIframe
            key='map'
            iframeUrl={iframeData?.mapIframeUrl}
            iframeKey={iframeKey}
            iframeUrlFetching={mutation.status === 'pending'}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <OmsetningIframe
            key='table'
            iframeUrl={iframeData?.tableIframeUrl}
            iframeKey={iframeKey}
            iframeUrlFetching={mutation.status === 'pending'}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <OmsetningFileDownload
            iframeId={iframeData?.id}
            iframeKey={iframeKey}
            iframeUrlFetching={mutation.status === 'pending'}
          />
        </CustomTabPanel>
      </ToiBox>

      <div style={{ display: 'flex', margin: 24, justifyContent: 'end' }}>
        <Link to='/personvern'>Personvernerklæring</Link>
      </div>
    </div>
  );
};

export default MainContent;
