import React from 'react';
import Dropdown from '../../Dropdown';
import { Option } from '../../../types';

interface ParentProps {
  selectedDayFilter?: number;
  onSelect: (value: number) => void;
}

const DayDropdown = (props: ParentProps) => {
  const values: number[] = [30, 60];
  const options: Option[] = values.map((day: number) => {
    return { name: day.toString(), value: day };
  });
  const onSelectionChanged = (event: any) => {
    if (event.target) {
      const value = event.target.value;
      if (value) {
        props.onSelect(value);
      }
    }
  };
  const dropdownName = 'DayDropdown';
  options.unshift({ name: ' ', value: undefined } as Option);
  return (
    <>
      <label htmlFor={dropdownName}>Siste antall dager:</label>
      <Dropdown
        name={dropdownName}
        ariaLabel={'select number of days from today'}
        items={options}
        onSelect={onSelectionChanged}
        value={props.selectedDayFilter}
      />
    </>
  );
};

export default DayDropdown;
