import React from 'react';
import styled from '../../styledComponents';
import { AuthState } from 'norkartidjs2';
import { appInsights } from '../../appInsights';
import { ToiButton, ToiSpinner } from '@norkart/toi-components';

interface ParentProps {
  Auth: AuthState;
}

const SignInBtn = (props: ParentProps) => {
  if (props.Auth.isLoggingIn) {
    return (
      <SignInStyled>
        <ToiSpinner />
        <h4>Logger deg inn..</h4>
      </SignInStyled>
    );
  } else if (!props.Auth.isLoggingIn && !props.Auth.isAuthorized && props.Auth.isAuthenticated) {
    const metricData = {
      average: 1,
      name: 'Signin-failed',
      sampleCount: 1,
    };
    const additionalProperties = { 'Component Name': 'SignIn' };
    appInsights.trackMetric(metricData, additionalProperties);
    return (
      <SignInFailedStyled>
        <h4>Innlogging feilet</h4>
        <ToiButton onClick={() => props.Auth.logOut}>Logg inn med en annen bruker</ToiButton>
        <p>
          <i>
            Dette kan skyldes at du ikke har logget inn med norkartid-bruker, prøv igjen med e-posten dere har fått
            tilsendt som ser slik ut: fornavn.etternavn@bedrift.norkartid.no.
          </i>
        </p>
      </SignInFailedStyled>
    );
  } else if (!props.Auth.isLoggingIn && !props.Auth.isAuthorized) {
    return (
      <SignInStyled>
        <ToiButton onClick={props.Auth.logIn}>Logg inn</ToiButton>
      </SignInStyled>
    );
  } else if (props.Auth.isAuthenticated && !props.Auth.isAuthorized) {
    return (
      <SignInFailedStyled>
        <p>Innlogging feilet - kontakt kundestøtte for å få innloggings tilgang</p>
        <ToiButton onClick={() => props.Auth.logOut}>Logg inn med en annen bruker</ToiButton>
      </SignInFailedStyled>
    );
  } else {
    return null;
  }
};

export default SignInBtn;

const SignInFailedStyled = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 40px;
  h4 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 20px;
  }
`;
const SignInStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  h4 {
    margin-left: 10px;
    margin-top: 33px;
  }
`;
