import { useMemo, useState } from 'react';
import styled from '../styledComponents';
import { useMutationState } from '@tanstack/react-query';
import { getIframeMutationKey, IframeMutationResponse, useGetIframeMutation } from '../hooks/useGetIframeMutation';
import { useIframeContext } from '../hooks/useIframeContext';
import { useSelectedFiltersContext } from '../hooks/useSelectedFiltersContext';
import { ToiButton, ToiSpinner, ToiStack } from '@norkart/toi-components';

interface ParentProps {
  iframeUrl?: string;
  title: string;
  shouldBePermanenturl?: boolean;
}

type Props = ParentProps;
const IframeButtonTextArea = (props: Props) => {
  const [opentextarea, setOpenText] = useState(false);
  const { incrementIframeKey } = useIframeContext();
  const { state } = useSelectedFiltersContext();
  const mutation = useGetIframeMutation({ incrementIframeKey }, state);

  const mutations = useMutationState({
    filters: { mutationKey: getIframeMutationKey },
    select: (mutation) => mutation.state,
  });

  const iframeData = useMemo(() => {
    if (mutations.length === 0) return;
    return mutations[mutations.length - 1].data as IframeMutationResponse | undefined;
  }, [mutations]);

  const handleClick = () => {
    mutation.mutate(props.shouldBePermanenturl);
    if (!opentextarea) {
      setOpenText(true);
    }
    setTimeout(() => {
      copyIframe();
    }, 100);
  };

  const copyIframe = () => {
    const textarea = document.getElementById('iframecopy') as HTMLInputElement;
    if (textarea) {
      textarea.select();
      document.execCommand('copy');
    }
  };

  return (
    <StyledIframeBtn
      key={props.title}
      opentextarea={opentextarea && iframeData?.isPermanent && props.iframeUrl ? true : false}
    >
      {opentextarea && iframeData?.isPermanent && props.iframeUrl ? (
        <div>
          <div style={{ display: 'flex', maxWidth: '99vh' }}>
            <div>
              <p style={{ margin: '12px' }}>Desktop tilpasset iframe</p>
              <textarea
                style={{ height: '120px' }}
                id='iframecopy'
                defaultValue={`<style>.norkart-embed{position:relative;height:0;width:100%;overflow:hidden;border:0;padding-top:56.25%}.norkart-embed iframe{overflow:hidden;position:absolute;top:0;left:0;width:100%;height:100%}</style><div class='norkart-embed'><iframe src='${
                  props.iframeUrl
                }' ${props.iframeUrl && props.iframeUrl.indexOf('table') > -1 ? '' : 'scrolling="no"'}></iframe></div>`}
              />
            </div>
            <div>
              <p style={{ margin: '12px' }}>Mobil tilpasset iframe</p>
              <textarea
                style={{ height: '120px' }}
                id='iframecopy'
                defaultValue={`<div style="height:450px"><style>.norkart-embed{position:relative;height:0;width:100%;border:0;padding-top:56.25%}.norkart-embed iframe{overflow:hidden;position:absolute;top:0;left:0;width:100%;height:200%}</style><div class='norkart-embed'><iframe src='${props.iframeUrl}'></iframe></div>`}
              />
            </div>
          </div>
        </div>
      ) : (
        <ToiStack flexDirection={'row'} alignItems={'center'} mb={2} gap={1}>
          <ToiButton variant='outlined' color='secondary' onClick={handleClick}>
            {props.title}
          </ToiButton>
          {mutation.status === 'pending' && (
            <ToiStack flexDirection={'row'} alignItems={'center'}>
              <ToiSpinner />
              <p>Henter iframe...</p>
            </ToiStack>
          )}
        </ToiStack>
      )}
    </StyledIframeBtn>
  );
};

export default IframeButtonTextArea;

const StyledIframeBtn = styled.div<{ opentextarea: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.opentextarea ? 'left' : 'center')};
  button {
    display: ${(props) => (props.opentextarea ? 'none' : 'flex')};
    max-width: 200px;
  }
  textarea {
    margin: 12px;
    height: 44px;
    width: 40vw;
  }
`;
