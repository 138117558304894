import { createContext, ReactNode, useContext, useReducer } from 'react';
import { IPriceFilter, ISelectable, IDateFilter } from '../types';

export type Action =
  | { type: 'SET_OMSETNINGSTYPE'; omsetningstype: ISelectable }
  | { type: 'REMOVE_OMSETNINGSTYPE'; omsetningstype: ISelectable }
  | { type: 'RESET_OMSETNINGSTYPE' }
  | { type: 'SET_MUNICIPALITY'; municipality: ISelectable }
  | { type: 'REMOVE_MUNICIPALITY'; municipality: ISelectable }
  | { type: 'RESET_MUNICIPALITY' }
  | { type: 'SET_GRUNNANDVENDELSER'; grunnandvendelse: ISelectable }
  | { type: 'REMOVE_GRUNNANDVENDELSER'; grunnandvendelse: ISelectable }
  | { type: 'RESET_GRUNNANVENDELSER' }
  | { type: 'SET_PRICEFILTER_TO'; toPrice?: number }
  | { type: 'SET_PRICEFILTER_FROM'; fromPrice?: number }
  | { type: 'RESET_PRICE' }
  | { type: 'RESET_DATEFILTER' }
  | { type: 'SET_DATEFILTER'; dateFilter: IDateFilter }
  | { type: 'SET_DAYSFILTER'; days: number }
  | { type: 'SET_MONTHFILTER'; month: string };

interface SelectedFiltersState {
  priceFilter?: IPriceFilter;
  selectedMunicipality: ISelectable[];
  selectedOmsetningstype: ISelectable[];
  selectedGrunnandvendelser: ISelectable[];
  days?: number;
  month?: string;
  dateFilter?: IDateFilter;
}

const initialState: SelectedFiltersState = {
  selectedMunicipality: [],
  selectedOmsetningstype: [],
  selectedGrunnandvendelser: [],
};

const getSelectedItems = (items: ISelectable[], id: string) => {
  return items && items.filter((x) => x.id !== id);
};

const setSelectedItems = (items: ISelectable[], item: ISelectable) => {
  return [...items, item];
};

export type SelectedFilterState = typeof initialState;

const selectedFiltersReducer = (state: SelectedFiltersState, action: Action): SelectedFiltersState => {
  switch (action.type) {
    case 'SET_OMSETNINGSTYPE':
      return {
        ...state,
        selectedOmsetningstype: setSelectedItems(state.selectedOmsetningstype, action.omsetningstype),
      };
    case 'REMOVE_OMSETNINGSTYPE':
      return {
        ...state,
        selectedOmsetningstype: getSelectedItems(state.selectedOmsetningstype, action.omsetningstype.id),
      };
    case 'RESET_OMSETNINGSTYPE':
      return {
        ...state,
        selectedOmsetningstype: [],
      };
    case 'SET_MUNICIPALITY':
      return { ...state, selectedMunicipality: setSelectedItems(state.selectedMunicipality, action.municipality) };
    case 'REMOVE_MUNICIPALITY':
      return {
        ...state,
        selectedMunicipality: getSelectedItems(state.selectedMunicipality, action.municipality.id),
      };
    case 'RESET_MUNICIPALITY':
      return {
        ...state,
        selectedMunicipality: [],
      };
    case 'SET_GRUNNANDVENDELSER':
      return {
        ...state,
        selectedGrunnandvendelser: setSelectedItems(state.selectedGrunnandvendelser, action.grunnandvendelse),
      };
    case 'REMOVE_GRUNNANDVENDELSER':
      return {
        ...state,
        selectedGrunnandvendelser: getSelectedItems(state.selectedGrunnandvendelser, action.grunnandvendelse.id),
      };
    case 'RESET_GRUNNANVENDELSER':
      return {
        ...state,
        selectedGrunnandvendelser: [],
      };
    case 'SET_DAYSFILTER':
      return {
        ...state,
        month: undefined,
        dateFilter: undefined,
        days: action.days,
      };
    case 'SET_MONTHFILTER':
      return {
        ...state,
        dateFilter: undefined,
        days: undefined,
        month: action.month,
      };
    case 'SET_DATEFILTER':
      return {
        ...state,
        month: undefined,
        days: undefined,
        dateFilter: action.dateFilter,
      };
    case 'SET_PRICEFILTER_FROM':
      return {
        ...state,
        priceFilter: { ...state.priceFilter, fromPrice: action.fromPrice },
      };
    case 'SET_PRICEFILTER_TO':
      return {
        ...state,
        priceFilter: { ...state.priceFilter, toPrice: action.toPrice },
      };
    case 'RESET_PRICE':
      return {
        ...state,
        priceFilter: undefined,
      };
    case 'RESET_DATEFILTER':
      return {
        ...state,
        month: undefined,
      };
    default:
      return state;
  }
};
const SelectedFiltersContext = createContext<
  | {
      state: SelectedFiltersState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined
>(undefined);

export const useSelectedFiltersContext = () => {
  const context = useContext(SelectedFiltersContext);
  if (!context) {
    throw new Error('useSelectedFiltersContext must be used within a SelectedFiltersProvider');
  }
  return context;
};

export const SelectedFiltersProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(selectedFiltersReducer, initialState);

  return <SelectedFiltersContext.Provider value={{ state, dispatch }}>{children}</SelectedFiltersContext.Provider>;
};
