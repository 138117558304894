import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ThemeProvider, theme } from '../styledComponents';
import { norkartIdJs } from '../util/authService';
import { AuthProvider } from 'norkartidjs2';
import RootTelemetry from './RootTelemetry';
import { IframeProvider } from '../hooks/useIframeContext';
import { SelectedFiltersProvider } from '../hooks/useSelectedFiltersContext';

const RootProvider = () => {
  return (
    <AuthProvider auth={norkartIdJs}>
      <IframeProvider>
        <SelectedFiltersProvider>
          <BrowserRouter>
            <RootTelemetry>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </RootTelemetry>
          </BrowserRouter>
        </SelectedFiltersProvider>
      </IframeProvider>
    </AuthProvider>
  );
};

export default RootProvider;
