import React from 'react';
import { ISelectable } from '../../../types';
import AutoComplete from '../../autoComplete/SearchableCheckboxList';
import { FilterComponentProps } from '../../../types';
import { useSelectedFiltersContext } from '../../../hooks/useSelectedFiltersContext';

interface KommuneFilterProps {
  municipalities: ISelectable[];
}

export const KommuneFilter = (props: FilterComponentProps & KommuneFilterProps) => {
  const { state, dispatch } = useSelectedFiltersContext();
  const setSelectedItems = (id: string, action: string) => {
    const item = props.municipalities.find((x) => x.name === id);
    if (!item) {
      return;
    }
    dispatch({ type: action as any, municipality: item });
  };

  return (
    <div className={props.className}>
      <AutoComplete
        removeAction={'REMOVE_MUNICIPALITY'}
        addAction={'SET_MUNICIPALITY'}
        items={props.municipalities}
        selectedItems={state.selectedMunicipality}
        onSelect={setSelectedItems}
        ariaLabel={'Multi-Select Municiplaity list'}
      />
    </div>
  );
};

export default KommuneFilter;
