import React, { FunctionComponent, useMemo, useState } from 'react';
import { ToiSpinner } from '@norkart/toi-components';
import styled, { theme, css } from '../../styledComponents';
import DateFilter from './date/DateFilter';
import OmsetningsFilterComponent from './omsetning/OmsetningsFilter';
import GrunnanvendelserFilterComponent from './Grunnanvendelser/GrunnanvendelserFilter';
import KommuneFilter from './kommuneFilter/KommuneFilter';
import PriceFilter from './omsetning/omsetningPrice/PriceFilter';
import IframeButtonTextArea from '../../components/IframeBtn';
import { useKommune } from '../../hooks/useGetKommune';
import { useGetGrunnanvendelse } from '../../hooks/useGetGrunnanvendelse';
import { useGetOmsetningsFilter } from '../../hooks/useGetOmsetningsFilter';
import { useSelectedFiltersContext } from '../../hooks/useSelectedFiltersContext';
import { getIframeMutationKey, IframeMutationResponse, useGetIframeMutation } from '../../hooks/useGetIframeMutation';
import { useMutationState } from '@tanstack/react-query';
import { useIframeContext } from '../../hooks/useIframeContext';

interface FilterWrapperProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}

const FilterWrapper: FunctionComponent<FilterWrapperProps> = ({ title, className, children }) => {
  return (
    <div className={className}>
      <div className='title'>{title}</div>
      <div>{children}</div>
    </div>
  );
};
const FilterComponent = () => {
  const { dispatch } = useSelectedFiltersContext();
  const { municipalities, isLoading: isKommuneLoading, isError: isKommuneError } = useKommune();
  const {
    grunnanvendelser,
    isLoading: isGrunnanvendelseLoading,
    isError: isGrunnanvendelseError,
  } = useGetGrunnanvendelse();
  const {
    omsetningstyper,
    isLoading: isOmsetningstyperLoading,
    isError: isOmsetningsFilterError,
  } = useGetOmsetningsFilter();
  const { state } = useSelectedFiltersContext();
  const { incrementIframeKey } = useIframeContext();

  const mutation = useGetIframeMutation({ incrementIframeKey }, state);

  const mutations = useMutationState({
    filters: { mutationKey: getIframeMutationKey },
    select: (mutation) => mutation.state,
  });

  const iframeData = useMemo(() => {
    if (mutations.length === 0) return;
    return mutations[mutations.length - 1].data as IframeMutationResponse | undefined;
  }, [mutations]);

  const resetFilters = () => {
    dispatch({ type: 'RESET_OMSETNINGSTYPE' });
    dispatch({ type: 'RESET_MUNICIPALITY' });
    dispatch({ type: 'RESET_GRUNNANVENDELSER' });
    dispatch({ type: 'RESET_PRICE' });
    dispatch({ type: 'RESET_DATEFILTER' });
    dispatch({ type: 'SET_DAYSFILTER', days: 30 });
    mutation.mutate(false);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const MenuCollapseClicked = () => setIsMenuOpen(!isMenuOpen);

  const getErrorText = (isGrunnanvendelseError: boolean, isKommuneError: boolean, isOmsetningsFilterError: boolean) => {
    if (isGrunnanvendelseError) {
      return 'Klarte ikke å hente ut grunnanvendelser';
    } else if (isKommuneError) {
      return 'Klarte ikke å hente ut tilgjengelige kommuner';
    } else if (isOmsetningsFilterError) {
      return 'Klarte ikke å hente ut omsetningstyper';
    } else {
      return 'En ukjent feil oppstod';
    }
  };

  return (
    <StyledMenu>
      <StyledFilterComponent isMenuOpen={true} shouldCenter={true}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
            Omsetningsfilter
          </h2>
          <div style={{ cursor: 'pointer' }} onClick={MenuCollapseClicked}>
            <StyledArrow aria-label={'show or hide filters'} isMenuOpen={isMenuOpen} />
          </div>
          {(isOmsetningstyperLoading || isKommuneLoading || isGrunnanvendelseLoading) && (
            <div style={{ marginLeft: '14px', alignItems: 'center' }}>
              <ToiSpinner /> Henter filter
            </div>
          )}
          {isGrunnanvendelseError ||
            isKommuneError ||
            (isOmsetningsFilterError && (
              <p style={{ marginLeft: '14px' }}>
                En feil oppstod: <i>{getErrorText(isGrunnanvendelseError, isKommuneError, isOmsetningsFilterError)}</i>
              </p>
            ))}
        </div>
        <StyledResetButton aria-label={'Resets the filters'} onClick={resetFilters} title={'Reset filterene'}>
          RESET
        </StyledResetButton>
      </StyledFilterComponent>
      <StyledFilterComponent isMenuOpen={isMenuOpen} shouldCenter={true}>
        <StyledFilterWrapper scroll={true} title={'Kommuner'}>
          {municipalities && <KommuneFilter municipalities={municipalities} />}
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={false} title={'Dato'}>
          <DateFilter />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={false} title={'Prisintervall'}>
          <PriceFilter />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={true} title={'Omsetningstyper'}>
          <OmsetningsFilterComponent omsetningstyper={omsetningstyper} />
        </StyledFilterWrapper>
        <StyledFilterWrapper scroll={true} title={'Grunnanvendelse'}>
          <GrunnanvendelserFilterComponent grunnandvendelser={grunnanvendelser} />
        </StyledFilterWrapper>
      </StyledFilterComponent>
      <UpdateDiv>
        <IframeButtonTextArea title='Oppdater' />
        <div>
          {iframeData?.count === 0 ? (
            <p>Valgt filter gir ingen omsetninger. Prøv med et mindre begrenset filter.</p>
          ) : (iframeData?.count as number) >= 10000 ? (
            <p>Filteret gir for mange omsetninger og overgår tillatt grense. Prøv med et mer begrenset filter.</p>
          ) : (iframeData?.count as number) < 0 ? (
            <p>Det oppstod en feil, undersøk om valgt filter er gyldig.</p>
          ) : null}
        </div>
      </UpdateDiv>
    </StyledMenu>
  );
};

export default FilterComponent;

const UpdateDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  div {
    display: flex;
    justify-content: center;
    p {
      padding: 9px;
      color: ${theme.color.green90};
    }
  }
`;

const StyledMenu = styled.div`
  width: 100%;
  background-color: ${theme.color.green5};
`;

const StyledFilterComponent = styled.div<{ isMenuOpen: boolean; shouldCenter?: boolean }>`
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90vw;
  ${(props) =>
    props.shouldCenter &&
    css`
      margin-left: auto;
      margin-right: auto;
    `};
  h2 {
    padding: 4px;
  }
`;
const StyledResetButton = styled.button`
  height: 42px;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
`;
const StyledArrow = styled.i<{ isMenuOpen: boolean }>`
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4.5px;
  margin-left: 5px;
  transform: rotate(${(props) => (props.isMenuOpen ? '-135deg' : '45deg')});
`;

const StyledFilterWrapper = styled(FilterWrapper)<{ scroll: boolean }>`
  height: 300px;
  width: 250px;
  padding: 8px;
  ${(props) =>
    props.scroll &&
    css`
      overflow: auto;
    `}
  div {
    margin-bottom: 12px;
  }
  .title {
    border-bottom: 1px solid ${theme.color.green90};
  }
`;
