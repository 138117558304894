import { SelectedFilterState } from '../hooks/useSelectedFiltersContext';
import { FilterBody, ISelectable } from '../types';

export function createFilterBody(selectedFiltersContext: SelectedFilterState): FilterBody {
  const reqBody = {
    dateFilter: [
      {
        type: 'LessThanOrEqual',
        days: 30,
      },
    ],
  } as FilterBody;
  if (selectedFiltersContext.priceFilter) {
    reqBody.priceFilter = [
      { type: 'GreaterThan', value: selectedFiltersContext.priceFilter.fromPrice },
      { type: 'LessThanOrEqual', value: selectedFiltersContext.priceFilter.toPrice },
    ];
  }
  if (selectedFiltersContext.selectedMunicipality) {
    reqBody.municipalityFilter = getIdListNumber(selectedFiltersContext.selectedMunicipality);
  }
  if (selectedFiltersContext.selectedGrunnandvendelser) {
    reqBody.grunnanvendelserFilter = getIdListString(selectedFiltersContext.selectedGrunnandvendelser);
  }
  if (selectedFiltersContext.selectedOmsetningstype) {
    reqBody.omsetningstypeFilter = getIdListNumber(selectedFiltersContext.selectedOmsetningstype);
  }
  if (selectedFiltersContext.month) {
    reqBody.monthFilter = parseInt(selectedFiltersContext.month, 0);
    reqBody.dateFilter = [];
  }
  if (selectedFiltersContext.days) {
    reqBody.monthFilter = undefined;
    reqBody.dateFilter[0].days = selectedFiltersContext.days;
    reqBody.dateFilter[0].type = 'LessThanOrEqual';
  }
  if (selectedFiltersContext.dateFilter) {
    reqBody.monthFilter = undefined;
    reqBody.dateFilter[0] = {
      type: 'GreaterThan',
      date: selectedFiltersContext.dateFilter.fromDate,
    };
    reqBody.dateFilter[1] = {
      type: 'LessThan',
      date: selectedFiltersContext.dateFilter.toDate,
    };
  }
  return reqBody;
}

const getIdListNumber = (list: ISelectable[]): number[] => {
  return list.map((x) => {
    return parseInt(x.id);
  });
};
const getIdListString = (list: ISelectable[]): string[] => {
  return list.map((x) => {
    return x.id;
  });
};
