import React from 'react';
import { ISelectable } from '../../../types';
import AutoComplete from '../../autoComplete/SearchableCheckboxList';
import { FilterComponentProps } from '../../../types';
import { useSelectedFiltersContext } from '../../../hooks/useSelectedFiltersContext';

interface OmsetningsFilterProps {
  omsetningstyper: ISelectable[];
}

const OmsetningsFilterComponent = (props: FilterComponentProps & OmsetningsFilterProps) => {
  const { state, dispatch } = useSelectedFiltersContext();

  const setSelectedItems = (id: string, action: string) => {
    const item = props.omsetningstyper.find((x) => x.name === id);
    if (!item) {
      return;
    }
    dispatch({ type: action as any, omsetningstype: item });
  };

  return (
    <div className={props.className}>
      <AutoComplete
        removeAction={'REMOVE_OMSETNINGSTYPE'}
        addAction={'SET_OMSETNINGSTYPE'}
        items={props.omsetningstyper}
        selectedItems={state.selectedOmsetningstype}
        onSelect={setSelectedItems}
        ariaLabel={'Multi-Select Omsetningstype'}
      />
    </div>
  );
};

export default OmsetningsFilterComponent;
