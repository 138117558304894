import { useMutation } from '@tanstack/react-query';
import { getOmsetningDataCount } from '../util/getOmsetningsdataCount';
import { getIframe, getIframePermanent } from '../util/apiService';
import { appInsights } from '../appInsights';
import { IframeContextProps } from './useIframeContext';
import { IframeResponseObject } from '../types';
import { createFilterBody } from '../util/createFilterBody';
import { SelectedFilterState } from './useSelectedFiltersContext';

export interface IframeMutationResponse extends IframeResponseObject {
  isPermanent: boolean;
  count?: number;
}

export const getIframeMutationKey = ['getIframe'];
export const useGetIframeMutation = (
  iframeContext: Partial<IframeContextProps>,
  selectedFiltersContext: SelectedFilterState,
) => {
  const putIframe = async (permanent?: boolean) => {
    const filterBody = createFilterBody(selectedFiltersContext);
    if (permanent) {
      return await getIframePermanent(filterBody)
        .then(async (res) => {
          const count = await getOmsetningDataCount(res.id);
          if (iframeContext?.incrementIframeKey) {
            iframeContext.incrementIframeKey();
          }
          return { ...res, isPermanent: true, count: count } as IframeMutationResponse;
        })
        .catch((error) => {
          appInsights.trackTrace(
            { message: 'req getiframeUrl Permanent rejected' },
            {
              selectedFilters: JSON.stringify(selectedFiltersContext),
              error: JSON.stringify(error),
            },
          );
        });
    } else {
      return await getIframe(filterBody)
        .then(async (res) => {
          const count = await getOmsetningDataCount(res.id);
          if (iframeContext?.incrementIframeKey) {
            iframeContext.incrementIframeKey();
          }
          return { ...res, isPermanent: false, count: count } as IframeMutationResponse;
        })
        .catch((error) => {
          appInsights.trackTrace(
            { message: 'req getiframeUrl notPermanent rejected' },
            {
              selectedFilters: JSON.stringify(selectedFiltersContext),
              error: JSON.stringify(error),
            },
          );
        });
    }
  };

  const mutation = useMutation({
    mutationKey: getIframeMutationKey,
    mutationFn: async (permanent?: boolean | undefined) => await putIframe(permanent),
  });

  return mutation;
};
