/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import * as _ from 'underscore';

const visitedKeys: string[] = [];
const getSetting = (key: string): string => {
  const value: string | null =
    // @ts-ignore
    (window.config && window.config[key]) || (global.__CONFIG && global.__CONFIG[key]) || process.env[key] || null;

  if (value === null) {
    console.error('Missing config key', key);
  } else {
    visitedKeys.push(key);
  }
  return value || '';
};
interface MsalConfig {
  clientId: string;
  profile: string;
}
interface Config {
  msal: MsalConfig;
  eiendomsomsetningApiKey: string;
  eiendomsomsetningBaseUrl: string;
  applicationInsightsKey: string;
}
export const appSettings: Config = {
  msal: {
    clientId: getSetting('REACT_APP_CLIENT_ID') || '4e235536-bda8-45c0-bb83-ee9ec409b3b0',
    profile: getSetting('REACT_APP_APPLICATION_PROFILE') || 'Eiendomsomsetning',
  },
  applicationInsightsKey: getSetting('REACT_APP_APPLICATION_INSIGHTS_KEY'),
  eiendomsomsetningApiKey: getSetting('REACT_APP_EIENDOMSOMSETNING_API_KEY'),
  eiendomsomsetningBaseUrl: getSetting('REACT_APP_EIENDOMSOMSETNING_BASE_URL'),
};

if ('config' in window) {
  const unnecessaryKeys: string[] = _.difference(Object.keys((window as any).config), visitedKeys);
  if (unnecessaryKeys.length >= 1) {
    // tslint:disable-next-line: no-console
    console.error('Received unnecessary setting keys', unnecessaryKeys);
  }
}
