import { useQuery } from '@tanstack/react-query';
import { getOmsetningtyper } from '../util/apiService';
import { appInsights } from '../appInsights';
import { ISelectable } from '../types';

export const useGetOmsetningsFilter = () => {
  const query = useQuery({ queryKey: ['getOmsetningtyper'], queryFn: async () => await getOmsetningtyper() });
  if (query.error) {
    appInsights.trackTrace(
      { message: 'req getOmsetningtyper rejected' },
      {
        error: JSON.stringify(query.error.message),
      },
    );
  }

  return { ...query, omsetningstyper: query.data ? toISelectable(query.data) : [] };
};

function toISelectable(response: { map: (arg0: (x: any) => ISelectable) => ISelectable[] }): ISelectable[] {
  return response.map((x: any) => {
    if (x.kommunenavn) {
      return { name: x.kommunenavn, id: x.kommunenummer } as ISelectable;
    }
    if (x.anvendelseKode) {
      return { name: x.anvendelseTekst, id: x.anvendelseKode } as ISelectable;
    }
    return { name: x.name, id: x.id } as ISelectable;
  });
}
