import React from 'react';
import { ISelectable } from '../../../types';
import AutoComplete from '../../autoComplete/SearchableCheckboxList';
import { FilterComponentProps } from '../../../types';
import { useSelectedFiltersContext } from '../../../hooks/useSelectedFiltersContext';

interface GrunnanvendelserFilterProps {
  grunnandvendelser: ISelectable[];
}

export const GrunnanvendelserFilter = (props: FilterComponentProps & GrunnanvendelserFilterProps) => {
  const { state, dispatch } = useSelectedFiltersContext();
  const setSelectedItems = (id: string, action: string) => {
    const item = props.grunnandvendelser.find((x) => x.name === id);
    if (!item) {
      return;
    }
    dispatch({ type: action as any, grunnandvendelse: item });
  };

  return (
    <div className={props.className}>
      <AutoComplete
        removeAction={'REMOVE_GRUNNANDVENDELSER'}
        addAction={'SET_GRUNNANDVENDELSER'}
        items={props.grunnandvendelser}
        selectedItems={state.selectedGrunnandvendelser}
        onSelect={setSelectedItems}
        ariaLabel={'Multi-Select Grunnanvendelser liste'}
      />
    </div>
  );
};

export default GrunnanvendelserFilter;
