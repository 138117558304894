import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';

export const Personvern = () => {
  return (
    <div style={{ margin: '60px auto', maxWidth: '1100px', paddingBottom: 60 }}>
      <Link to='/'>Tilbake</Link>

      <h1>Personvernerklæring</h1>
      <p>
        Norkart er behandlingsansvarlig for behandlingen av personopplysninger som beskrevet i denne
        personvernerklæringen. Vi forklarer her hva slags personopplysninger vi lagrer og hvordan de behandles. Denne
        personvernerklæringen gjelder for eiendomsomsetninger.norkart.no og ditt kundeforhold med Norkart. Norkart
        behandler og videreformidler eiendomsinformasjon fra de offentlige registrene for matrikkel og grunnbok.
        Behandlingen og videreformidlingen skjer etter bestemmelsene i matrikkelutleveringsforskriften.
      </p>
      <p>Vår postadresse er:</p>
      <ul style={{ listStyleType: 'none' }}>
        <li>Norkart AS</li>
        <li>Hoffsveien 4</li>
        <li>0275 Oslo</li>
      </ul>
      <p style={{ marginBottom: 0 }}>Telefonnummeret vårt er 67 55 14 00.</p>
      <p style={{ marginTop: 0 }}>Du kan også ta kontakt med oss på: personvern@norkart.no.</p>
      <hr style={{ margin: '48px 0' }} />
      <h2>Personopplysninger vi behandler om deg for å levere nettsiden eiendomsomsetninger.norkart.no:</h2>
      <ul>
        <li>IP-adresse</li>
        <li>Operativsystem</li>
        <li>Nettleserinformasjon</li>
        <li> Skjermoppløsning</li>
        <li> Type enhet</li>
        <li> Navn</li>
        <li>Tidspunkt</li>
        <li>Arbeidssted</li>
      </ul>
      <p>Disse opplysningene blir slettet etter 90 dager.</p>
      <p>
        Vi bruker dine personopplysninger for å levere tjenesten til deg. Det kan for eksempel være for å levere
        produktet til deg, og kundesupport knyttet til ditt kundeforhold. Det rettslige grunnlaget for å behandle
        personopplysninger til dette formålet er at behandlingen er nødvendig for å oppfylle en avtale med deg.
      </p>
      <hr style={{ margin: '48px 0' }} />
      <h2>Våre underleverandører for databehandling</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Formål</TableCell>
            <TableCell>Datalagringsregion</TableCell>
            <TableCell>Kontakt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Hubspot</TableCell>
            <TableCell>Lagre og analysere informasjon om kunder og brukere, samt e-post-kommunikasjon</TableCell>
            <TableCell>EU</TableCell>
            <TableCell>{"1 Sir John Rogerson's Quay, Dublin 2"}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Zendesk</TableCell>
            <TableCell>Kundestøtte</TableCell>
            <TableCell>EU</TableCell>
            <TableCell>
              Privacy Team and DPO, 989 Market Street, San Francisco, CA 94103, United States, euprivacy@zendesk.com
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Microsoft</TableCell>
            <TableCell>Skytjenester for å levere tjenesten og autentisering</TableCell>
            <TableCell>EU</TableCell>
            <TableCell>
              Microsoft Ireland Operations Limited One Microsoft Place, South County Business Park, Leopardstown, Dublin
              18 D18 P521
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Xledger</TableCell>
            <TableCell>Fakturering</TableCell>
            <TableCell>EU</TableCell>
            <TableCell>Østensjøveien 32, Postboks 6662, Etterstad, 0609 Oslo</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <hr style={{ margin: '48px 0' }} />
      <h2>Dine rettigheter som bruker av våre nettsteder eller som kunde av oss:</h2>
      <p>Dersom du ønsker å bruke noen av dine rettigheter, ta kontakt med oss på personvern@norkart.no.</p>
      <p>
        Personopplysninger som finnes i offentlige eiendomsregistre slik som matrikkel og grunnbok, kan Norkart ikke
        endre eller slette. Hvis du mener informasjonen om deg i slike registre ikke er korrekt, ta kontakt med din
        kommune.
      </p>
      <h3>Rett til innsyn i egne opplysninger</h3>
      <p>Du kan be om en kopi av alle opplysninger vi behandler om deg.</p>
      <h3>Rett til sletting og korrigering av personopplysninger</h3>
      <p>
        Du har rett til å be oss rette eller supplere opplysninger som er feilaktige eller misvisende. Du kan også ha
        rett til å få dine personopplysninger slettet uten ugrunnet opphold. Men merk at informasjon som vi er pålagt å
        beholde av hensyn til andre rettslige forpliktelser (som for eksempel bokføringsloven) ikke vil bli slettet.
      </p>
      <h3>Begrensning av behandling av personopplysninger</h3>
      <p>
        I noen situasjoner kan du også be oss begrense behandlingen av opplysninger om deg. Dette gjør du ved å
        administrere samtykker eller reservasjoner i våre løsninger.
      </p>
      <h3>Protestere mot en behandling av personopplysninger</h3>
      <p>
        Dersom vi behandler opplysninger om deg med grunnlag i våre oppgaver eller på bakgrunn av en interesseavveining,
        har du rett til å protestere på vår behandling av opplysninger om deg. Dette gjør du ved å administrere
        samtykker eller reservasjoner i våre løsninger (nyhetsbrev).
      </p>
      <h3>Du kan klage på vår behandling av personopplysninger</h3>
      <p>
        Vi håper du vil si ifra dersom du mener vi ikke overholder reglene i personopplysningsloven. Si da gjerne først
        i fra gjennom den kontakten eller kanalen du allerede har etablert med oss. Du kan også klage over vår
        behandling av personopplysninger, skriftlig til Datatilsynet: Datatilsynet, Postboks 458 Sentrum, 0105 Oslo.
      </p>
    </div>
  );
};
