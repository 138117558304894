import { useQuery } from '@tanstack/react-query';
import { getKommuner } from '../util/apiService';
import { appInsights } from '../appInsights';
import { ISelectable } from '../types';

export const useKommune = () => {
  const query = useQuery({ queryKey: ['getKommuner'], queryFn: async () => await getKommuner() });
  if (query.error) {
    appInsights.trackTrace(
      { message: 'req getKommuneFilter rejected' },
      {
        error: JSON.stringify(query.error.message),
      },
    );
  }

  const selectableMunicipalities = query.data ? toISelectable(query.data.kommuner) : [];
  const sortedMuniList = sortListAlphabetically(selectableMunicipalities ? selectableMunicipalities : []);

  return { ...query, municipalities: query.data ? sortedMuniList : [] };
};

const sortListAlphabetically = (list: ISelectable[]) => {
  return list.sort((a: ISelectable, b: ISelectable) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

function toISelectable(response: { map: (arg0: (x: any) => ISelectable) => ISelectable[] }): ISelectable[] {
  return response.map((x: any) => {
    if (x.kommunenavn) {
      return { name: x.kommunenavn, id: x.kommunenummer } as ISelectable;
    }
    if (x.anvendelseKode) {
      return { name: x.anvendelseTekst, id: x.anvendelseKode } as ISelectable;
    }
    return { name: x.name, id: x.id } as ISelectable;
  });
}
