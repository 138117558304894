import { createContext, useContext, useState } from 'react';

export interface IframeContextProps {
  iframeKey: number;
  incrementIframeKey: () => void;
}

const IframeContext = createContext<IframeContextProps | undefined>(undefined);

export const useIframeContext = () => {
  const context = useContext(IframeContext);
  if (!context) {
    throw new Error('useIframeContextProps must be used within a IframeProvider');
  }
  return context;
};

interface IframeProviderProps {
  children: React.ReactNode;
}

export const IframeProvider = ({ children }: IframeProviderProps) => {
  const [iframeKey, setIframeKey] = useState(0);

  const incrementIframeKey = () => {
    setIframeKey((prevKey) => prevKey + 1);
  };

  return <IframeContext.Provider value={{ iframeKey, incrementIframeKey }}>{children}</IframeContext.Provider>;
};
