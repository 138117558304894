import { getOmsetningerCount } from '../util/apiService';

export const getOmsetningDataCount = (id: string): Promise<number> => {
  return getOmsetningerCount(id).then(
    (resp) => {
      if (resp) {
        return resp.items;
      }
      return 0;
    },
    () => {
      return -1;
    },
  );
};
