import React from 'react';
import PriceInput from './PriceInput';
import styled from '../../../../styledComponents';
import { useSelectedFiltersContext } from '../../../../hooks/useSelectedFiltersContext';

const PriceFilter = () => {
  const { state, dispatch } = useSelectedFiltersContext();

  const setFromPrice = (price?: number) => {
    dispatch({ type: 'SET_PRICEFILTER_FROM', fromPrice: price });
  };
  const setToPrice = (price?: number) => {
    if (price) {
      dispatch({ type: 'SET_PRICEFILTER_TO', toPrice: price });
    } else {
      dispatch({ type: 'RESET_PRICE' });
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledPriceFilter>
        <PriceInput
          name={'from'}
          inputValue={state.priceFilter ? state.priceFilter.fromPrice : 0}
          ariaLabel={'Omsetning from price'}
          label={'Fra'}
          onChange={setFromPrice}
        />
      </StyledPriceFilter>
      <StyledPriceFilter>
        <PriceInput
          name={'To'}
          label={'Til'}
          inputValue={state.priceFilter ? state.priceFilter.toPrice : 0}
          onChange={setToPrice}
          ariaLabel={'Omsetning to price'}
        />
      </StyledPriceFilter>
    </div>
  );
};

export default PriceFilter;

const StyledPriceFilter = styled.div`
  label {
    padding: 6px;
  }
`;
