import { downloadCsvWithFilterId, downloadHtmlWithFilterId, downloadTextWithFilterId } from '../util/apiService';

export const downloadFilteredData = (filterId: string | undefined, filetype: 'csv' | 'text' | 'html') => {
  if (!filterId) {
    return;
  }
  if (filetype === 'csv') {
    window.open(downloadCsvWithFilterId(filterId), '_blank');
  } else if (filetype === 'text') {
    window.open(downloadTextWithFilterId(filterId), '_blank');
  } else if (filetype === 'html') {
    window.open(downloadHtmlWithFilterId(filterId), '_blank');
  }
};
