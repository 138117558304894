import React from 'react';
import styled from '../../styledComponents';
import { ToiButton } from '@norkart/toi-components';
import { downloadFilteredData } from '../../util/downloadFilteredData';

interface Props {
  iframeId?: string;
  iframeKey: number;
  iframeUrlFetching: boolean;
}

const OmsetningFileDownload = (props: Props) => {
  const downloadCsvClicked = () => {
    if (props.iframeId) {
      downloadFilteredData(props.iframeId, 'csv');
    }
  };
  const downloadTextClicked = () => {
    if (props.iframeId) {
      downloadFilteredData(props.iframeId, 'text');
    }
  };
  const downloadHtmlClicked = () => {
    if (props.iframeId) {
      downloadFilteredData(props.iframeId, 'html');
    }
  };
  const textUrl = '/TextView/' + props.iframeId;
  return (
    <StyledIframeArea hasIframeUrl={props.iframeId || !props.iframeUrlFetching ? true : false}>
      <StyledBtn>
        <ToiButton variant='outlined' color='secondary' onClick={downloadCsvClicked}>
          Last ned omsetningsdata (csv-fil)
        </ToiButton>
        <ToiButton variant='outlined' color='secondary' onClick={downloadTextClicked}>
          Last ned omsetningsdata (tekst-fil)
        </ToiButton>
        <ToiButton variant='outlined' color='secondary' onClick={downloadHtmlClicked}>
          Last ned omsetningsdata (formatert HTML)
        </ToiButton>
      </StyledBtn>

      <div>
        {props.iframeId || !props.iframeUrlFetching ? (
          <iframe key={props.iframeKey} src={textUrl} title='Innsyn i omsetningsdata' />
        ) : (
          <div style={{ height: '80vh' }}>
            <img alt='Et stillbilde' />
          </div>
        )}
      </div>
    </StyledIframeArea>
  );
};

export default OmsetningFileDownload;

const StyledBtn = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
`;
const StyledIframeArea = styled.div<{ hasIframeUrl: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .iframe-btn {
    display: 'flex';
    flexdirection: 'row';
  }
  iframe {
    height: 60vh;
    width: 60vw;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
`;
