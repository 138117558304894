import { ToiLink, ToiStack, ToiTypography } from '@norkart/toi-components';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <ToiStack>
      <ToiTypography variant='h1'>Siden ikke funnet</ToiTypography>
      <ToiLink to='/' component={Link}>
        Gå til forsiden
      </ToiLink>
    </ToiStack>
  );
};

export default NotFound;
