import React, { useState, useRef, useEffect } from 'react';
import { ToiCheckbox, ToiDivider, ToiFormControlLabel, ToiStack, ToiTypography } from '@norkart/toi-components';
import { ISelectable } from '../../types';

interface ParentProps {
  items: ISelectable[];
  selectedItems: ISelectable[];
  removeAction: string;
  addAction: string;
  className?: string;
  ariaLabel: string;
  onSelect(id: string, action: string): void;
}
interface GlobalStateProps {
  searchTerm: string;
  showSearchAbleItems: boolean;
  isAllSelected: boolean;
}

export const SearchableCheckboxList = (props: ParentProps) => {
  const [state, setState] = useState<GlobalStateProps>({
    searchTerm: '',
    showSearchAbleItems: true,
    isAllSelected: false,
  });
  const textInput = useRef<HTMLInputElement>(null);
  const autoComplete = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (autoComplete.current && !autoComplete.current.contains(event.target)) {
      if (props.selectedItems && props.selectedItems.length > 0) {
        setState((prev) => {
          return { ...prev, showSearchAbleItems: true };
        });
      }
    }
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, searchTerm: event.target.value });
  };

  const getCheckBox = (items: ISelectable[], isSelected: boolean) => {
    const action = isSelected ? props.removeAction : props.addAction;
    return items.map((item, index) => {
      return (
        <div key={index} style={{ marginBottom: '8px', marginLeft: '8px' }}>
          <ToiFormControlLabel
            onChange={() => {
              setIsAllSelectedState(false);
              props.onSelect(item.name, action);
            }}
            sx={{ alignItems: 'center' }}
            label={
              <ToiTypography variant='body3' sx={{ ml: 1 }}>
                {item.name}
              </ToiTypography>
            }
            control={<ToiCheckbox checked={isSelected} />}
          />
        </div>
      );
    });
  };

  const onInputFocus = () => {
    if (!state.showSearchAbleItems) {
      setState((prev) => {
        return { ...prev, showSearchAbleItems: true };
      });
    }
  };

  const setIsAllSelectedState = (value: boolean) => {
    setState((prev) => {
      return { ...prev, isAllSelected: value };
    });
  };

  const selectedItems = props.selectedItems;
  const searchAbleItems = props.items.filter((x: ISelectable) => {
    const findItem = props.selectedItems.find((item: ISelectable) => item.name === x.name);
    if (!findItem) {
      return true;
    } else {
      return false;
    }
  });

  const setAllStates = (isAllSelected: boolean) => {
    const action = isAllSelected ? props.addAction : props.removeAction;
    props.items.forEach((x) => props.onSelect(x.name, action));
  };

  return (
    <div ref={autoComplete}>
      <ToiStack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
        <input
          ref={textInput}
          className={props.className}
          onChange={onSearch}
          type='text'
          onFocus={onInputFocus}
          aria-label={props.ariaLabel}
          placeholder={'Søk etter filter'}
          style={{
            fontFamily: 'inherit',
            width: '100%',
            maxWidth: '300px',
            border: 0,
            borderBottom: '1px solid #d2d2d2',
            outline: 0,
            fontSize: '14px',
            color: '#212121',
            background: 'transparent',
            transition: 'border-color 0.2s',
            marginBottom: '10px',
          }}
        />
        <ToiFormControlLabel
          onChange={() => {
            const isAllSelected = !state.isAllSelected;
            setAllStates(isAllSelected);
            setIsAllSelectedState(isAllSelected);
          }}
          label={''}
          aria-label='Velg alle'
          sx={{ alignItems: 'center' }}
          control={<ToiCheckbox checked={state.isAllSelected} />}
        />
      </ToiStack>
      <ToiStack flexDirection={'column'}>
        {selectedItems && selectedItems.length > 0 && (
          <>
            <div>{getCheckBox(selectedItems, true)}</div>
            {selectedItems && selectedItems.length > 0 && searchAbleItems.length > 0 && state.showSearchAbleItems && (
              <ToiStack mb={1}>
                <ToiDivider size='medium' />
              </ToiStack>
            )}
          </>
        )}

        {state.showSearchAbleItems && (
          <div>
            {searchAbleItems &&
              getCheckBox(
                searchAbleItems.filter((x) =>
                  x.name.toLocaleLowerCase().includes(state.searchTerm.toLocaleLowerCase()),
                ),
                false,
              )}
          </div>
        )}
      </ToiStack>
    </div>
  );
};

export default SearchableCheckboxList;
