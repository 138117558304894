import { MsalConfig, NorkartIdJsMsal } from 'norkartidjs2';
import { appSettings } from '../appSettings';

const norkartIdConfig: MsalConfig = {
  clientId: appSettings.msal.clientId,
  postLogoutUrl: window.location.origin,
  profile: appSettings.msal.profile,
  useApplicationBackend: true,
  cacheLocation: 'sessionStorage',
};

export const norkartIdJs = new NorkartIdJsMsal(norkartIdConfig);
